<template>
  <q-card class="q-ma-sm">
    <q-card-section>
      <div class="text-h5">
        {{ this.getRes("Document.Breadcrumbs.ApplicationForm") }}
      </div>
    </q-card-section>

    <q-separator />

    <q-card-section class="q-pt-sm">
      <q-tree
        ref="t"
        :nodes="qTree"
        node-key="id"
        :selected="selected"
        @update:selected="treeNodeSelected"
        default-expand-all
        no-transition
      >
        <template #default-header="{ node }">
          <div class="row justify-between full-width">
            <div>{{ node.label }}</div>
            <div v-if="node.action.route || node.action.messages">
              <q-icon name="PROSmart-Edit" />
            </div>
          </div>
        </template>
      </q-tree>
    </q-card-section>
  </q-card>
</template>

<script>
import ProStepperMixin from "@/views/mixins/ProStepperMixin";

export default {
  name: "documentApplicationForm",
  mixins: [ProStepperMixin],
  data() {
    return {
      qTree: [],
      selected: "",
    };
  },
  methods: {
    treeNodeSelected(key) {
      const node = this.$refs.t.getNodeByKey(key);
      if (node.action.route) {
        this.$router.push(node.action.route);
      } else if (node.action.messages) {
        this.showErrorMessages(node.action.messages);
      }
    },
    actionsAreEqual(action1, action2) {
      if (!action1 && !action2) return false; //both null consider is not equal

      if (action1.messages && action2.messages) {
        //compare messages
        return (
          action1.messages.length === action2.messages.length &&
          action1.messages.every((m1) => action2.messages.includes(m1))
        );
      }

      if (action1.route && action2.route) {
        //compare routes
        return (
          action1.route.name === action2.route.name &&
          action1.route.params.mode === action2.route.params.mode &&
          action1.route.params.code === action2.route.params.code &&
          action1.route.params.stepperId === action2.route.params.stepperId
        );
      }

      return false;
    },
  },
  created() {
    const stepperList = JSON.parse(
      sessionStorage.getItem("stepperList") || "[]"
    );
    const actionMenuList = JSON.parse(
      sessionStorage.getItem("actionMenuList") || "[]"
    );

    if (stepperList.length) {
      const actionMenuMap = actionMenuList.reduce((map, currentGroup) => {
        if (currentGroup.menuItems.length) {
          const stepperId = currentGroup.menuItems[0].stepperId; //all item in group have same stepperId
          const menuItems = currentGroup.menuItems.map((item) => {
            const itemAction = this.getActionMenuAction(item);
            return {
              id: `${item.stepperId}-${item.id}`,
              label: this.getRes(item.descriptionRkey),
              action: itemAction,
              selectable: !!(itemAction.route || itemAction.messages),
            };
          });

          map[stepperId] = (map[stepperId] || []).concat(menuItems);
        }
        return map;
      }, {});

      this.qTree = stepperList
        // .filter(
        //   (parentNode) =>
        //     parentNode.editRoute ||
        //     parentNode.childrenStepperCode.some(
        //       (childNode) => childNode.editRoute
        //     )
        // )
        .map((parentNode) => {
          const parentAction = this.getStepperAction(parentNode);
          const childrenStepper = parentNode.childrenStepperCode
            // .filter((childNode) => childNode.editRoute)
            .map((childNode) => {
              const childAction = this.getStepperAction(childNode);
              const childActionMenus = (
                actionMenuMap[childNode.id] || []
              ).filter(
                (item) => !this.actionsAreEqual(item.action, childAction) //exclude same action
              );

              return {
                id: childNode.id,
                label: this.getRes(childNode.descriptionRkey),
                action: childAction,
                selectable: !!(childAction.route || childAction.messages),
                children: childActionMenus,
              };
            });
          const parentActionMenus = (actionMenuMap[parentNode.id] || []).filter(
            (item) => !this.actionsAreEqual(item.action, parentAction) //exclude same action
          );

          return {
            id: parentNode.id,
            header: "title",
            label: this.getRes(parentNode.descriptionRkey),
            action: parentAction,
            selectable: !!(parentAction.route || parentAction.messages),
            children: parentActionMenus.concat(childrenStepper),
          };
        });
      // this.$nextTick(this.$refs.t.expandAll);
    }
  },
};
</script>
